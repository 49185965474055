/***********************/
/*   Form validation   */
/***********************/
$(function(){

    var messages = {
        'success': {
            'subscription': 'Je aanmelding is succesvol verzonden!',
            'contact': 'Het formulier is succesvol verzonden!'
        },
        'error': {
            'requiredFields': 'Je hebt niet alle verplichte velden (correct) ingevuld.',
            'serverError': 'Er is iets misgegaan, probeer het later nog eens.'
        }
    };

    var debug = false;
    var errors;

    $('form#form-subscribe.validate').on('submit',function(e){
        e.preventDefault();

        var f = $(this);

        errors = 0;

        if(debug) {
            console.warn('Form submit, form data:');
        }

        var formData = {
            'cm-name'               : f.find('input[name="name"]').val(),
            'cm-ihihykl-ihihykl'    : f.find('input[name="email"]').val()
        };

        var hp = 0;
        if(f.find('input[name="hp"]').val() == '') {
            hp++;
        }
        if(f.find('input[name="le"]').val() == '') {
            hp++;
        }

        if(!validateFields(f)) {
            shakeErrors(f);
            showMessage(f, messages.error.requiredFields, 'error');
        }
        else {
            var button = f.find('button[type=submit]');
            button.attr('disabled', true);

            if (debug) {
                console.log('Form validated successfully!');
            }

            showMessage(f, '', '');

            if(hp == 2) {

                $.post('https://digidee.createsend.com/t/r/s/ihihykl/', formData,
                    function (data, textStatus, jqXHR) {
                        if (jqXHR.status === 200) {
                            f.children('.form-group-inline').remove();
                            showMessage(f, messages.success.subscription, 'success');
                        }
                        else {
                            showMessage(f, messages.error.serverError, 'error');
                        }
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        showMessage(f, messages.error.serverError, 'error');
                    });
            }
            else {
                // possible bot, so ignore
                f.children('.form-group-inline').remove();
                showMessage(f, messages.success.subscription, 'success');
            }
        }

        if (debug) {
            console.log('Number of errors: ' + errors);
        }

        e.preventDefault();
        return false;
    });


    $('form#form-contact.validate').on('submit',function(e){

        var f = $(this);

        errors = 0;

        if(debug) {
            console.warn('Form submit, form data:');
        }

        var formData = {
            'name'          : $(this).find('input[name=name]').val(),
            'email'         : $(this).find('input[name=email]').val(),
            'phoneNumber'   : $(this).find('input[name=phoneNumber]').val(),
            'message'       : $(this).find('textarea[name=message]').val(),
            'hp'            : $(this).find('input[name=hp]').val(),
            'le'            : $(this).find('input[name=le]').val()
        };

        if(!validateFields(f)) {
            shakeErrors(f);
            showMessage(f, messages.error.requiredFields, 'error');
        }
        else {
            var button = f.find('button[type=submit]');
            button.attr('disabled',true);

            if(debug) {
                console.log('Form validated successfully!');
            }

            $.post(_mgnl.contextPath + '/.rest/contact/v1', formData,
                function (data, textStatus, jqXHR) {
                    if(jqXHR.status === 200) {
                        f.prev('.intro').remove();
                        f.children('.form-group-inline, .form-grouped').remove();
                        showMessage(f, messages.success.contact, 'success');
                    }
                    else {
                        showMessage(f, messages.error.serverError, 'error');
                    }
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    showMessage(f, messages.error.serverError, 'error');
                });
        }

        if(debug) {
            console.log('Number of errors: '+errors);
        }

        e.preventDefault();
        return false;
    });



    function showMessage(form, content, type) {
        var m = form.find('.message');
        m.removeClass('error success').html(content).addClass(type);
    }

    function shakeErrors(form) {
        form.find('.input-wrapper.error, .select-box.error, .label-cb.error').addClass('shake');

        setTimeout(function() {
            form.find('.input-wrapper.error, .select-box.error, .label-cb.error').removeClass('shake');
        },800);
    }

    function validateFields(form) {
        form.find('.validate-field').each(function(){
            if(!validateField($(this))) {
                errors++;
            }
        });

        if(errors === 0) {
            return true;
        }
        else {
            return false;
        }
    }

    function setFieldErrorStatus(field,bool) {
        var errorField = field;
        if(field.is('select')) {
            errorField = field.siblings('.select-box');
        }
        if(field.is(':checkbox')) {
            errorField = field.siblings('.label-cb');
        }

        if(bool) {
            errorField.removeClass('valid').addClass('error').parent().addClass('error');
        }
        else {
            errorField.removeClass('error').addClass('valid').parent().removeClass('error');
            //errorField.siblings('.message').remove();
        }
    }

    function checkPattern(pattern,field,label) {
        if(pattern.test(field.val()) && field.val() !== '') {
            return true;
        }
        else {
            return false;
        }
    }

    function validateField(field) {
        var rule = field.data('validate');
        var label = field.siblings('label.validation');

        if(field.is('div')) {
            if(field.hasClass('validate-field') && field.siblings('input').val() !== field.data('placeholder')) {
                isValid(field,label);
                return true;
            }
            else {
                isInvalid(field,label);
                return false;
            }
        }

        var pattern = '';

        if(field.hasClass('skip-validate')){
            return true;
        }

        if(rule == 'required') {
            if(field.is(':checkbox, :radio')) {
                if(field.prop('checked')) {
                    isValid(field,label);
                    return true;
                }
                else {
                    isInvalid(field,label);
                    return false;
                }
            }
            else {
                if(field.val() !== '') {
                    isValid(field,label);
                    return true;
                }
                else {
                    isInvalid(field,label);
                    return false;
                }
            }
        }
        if(rule == 'email') {
            pattern = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
            if(checkPattern(pattern,field,label)) {
                isValid(field,label);
                return true;
            }
            else {
                isInvalid(field,label);
                return false;
            }
        }
        if(rule == 'phone') {

            if(field.val() !== '') {
                var vast = /^(((\+31|0|0031)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))$/;
                var mobiel = /^(((\+31|0|0031)6){1}[-]?[1-9]{1}[0-9]{7})$/;

                if (checkPattern(vast, field, label) || checkPattern(mobiel, field, label)) {
                    isValid(field, label);
                    return true;
                }
                else {
                    isInvalid(field, label);
                    return false;
                }
            }
            else {
                isValid(field, label);
                return true;
            }
        }
        if(rule == 'int') {
            pattern = /^[0-9]*$/;
            if(checkPattern(pattern,field,label)) {
                isValid(field,label);
                return true;
            }
            else {
                isInvalid(field,label);
                return false;
            }
        }
    }

    function isValid(field,label) {
        label.removeClass('error valid').addClass('valid');
        field.removeClass('reg-form');
        if(debug) {
            console.debug('Valid: ',field);
        }
        setFieldErrorStatus(field, false);
    }

    function isInvalid(field,label) {
        label.removeClass('error valid').addClass('error');
        if(field.parent().hasClass('reg-form-wrapper')) {
            field.addClass('reg-form');
        }
        setFieldErrorStatus(field, true);

        if(debug) {
            console.debug('Invalid: ',field);
        }
    }

    $('form.validate .validate-field').not('[readonly]').on('blur click keyup',function(){
        validateField($(this));
    });

    $('form.validate input[type="file"].validate-field').on('change',function(){
        validateField($(this));
    });
});
$(function(){
    $('.haschildren').each(function(){
        var c = $(this).children('a');
        var e = $('<button class="toggle-expand"><i class="icon icon-angle-down"></i></button>');
        e.insertAfter(c);
    });

    $('.toggle-nav, .nav-underlay').on('click',function(){
        var t = $('.toggle-nav');
        var h = $('header');

        if(t.hasClass('expand')) {
            t.removeClass('expand');
            h.removeClass('nav-open');
            $('body').css('overflow','auto');
        }
        else {
            t.addClass('expand');
            h.addClass('nav-open');
            $('body').css('overflow','hidden');
        }
    });

    $('.toggle-expand').on('click',function(){
        var sub = $(this).siblings('.wrapper').children('ul');
        if(sub.hasClass('expanded')) {
            sub.removeClass('expanded');
            $(this).find('.icon').removeClass('icon-angle-up').addClass('icon-angle-down');
        }
        else {
            sub.addClass('expanded');
            $(this).find('.icon').removeClass('icon-angle-down').addClass('icon-angle-up');
        }
    });

    // function to shuffle javascript array
    function shuffle(array) {
        var currentIndex = array.length,
            temporaryValue,
            randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    var perSlide = 12;
    var items = [];

    $("#sponsors .list .sponsor").each(function() {
        var b = $(this);
        items.push(b);
    });

    items = shuffle(items);

    // max 3 slides, so calculate
    //perSlide = Math.ceil(items.length / 3);

    for (var i = 0, j = items.length; i < j; i += perSlide) {
        var tempArray = items.slice(i, i + perSlide);
        var p = $('<div class="panel"></div>');
        var li = $('<li></li>');

        // fill panel with randomized elements
        for (var k = 0; k < tempArray.length; k++) {
            p.append(tempArray[k]);
        }

        // add panel to list item
        p.appendTo(li);

        // add list item to slider
        li.appendTo("#lightSlider");
    }

    var autoplaySlider = $("#lightSlider").lightSlider({
        auto: true,
        loop: true,
        speed: 1000,
        pause: 5000,
        controls: false,
        item: 1,
        pauseOnHover: true,
        adaptiveHeight: false,
        enableTouch: true,
        enableDrag: true
    });


    // video component

    var videoRatio = parseFloat(760/430);

    function calculateVideoSize() {
        var w = $(window).innerWidth() - 40;
        var r = {};

        if(w <= 768) {
            r.width = w;
            r.height = w / videoRatio;
        }
        $('.video-container iframe').css({'width':r.width,'height':r.height});
    }

    if($('.video-container').length > 0){
        $('.video-container').each(function(){
            var s = $(this);
            var url = s.find('span').html();
            var youtubeVideoId = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
            var vimeoVideoId = url.match(/https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/);

            if(youtubeVideoId !== null) {
                s.html('<iframe src="https://www.youtube.com/embed/'+youtubeVideoId[1]+'?rel=0&amp;showinfo=0" width="760" height="430" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
            }
            if(vimeoVideoId !== null) {
                s.html('<iframe src="https://player.vimeo.com/video/'+vimeoVideoId[3]+'" width="760" height="430"  frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
            }
            if (youtubeVideoId === null && vimeoVideoId===null){
                s.html('Ongeldige video link');
            }
        });

        calculateVideoSize();

        $(window).on('resize',function(){
            calculateVideoSize();
        });

    }



    // map component
    if($('.branch-map').length == 1){
        var d = $('.branch-map').data();

        var map = new GMaps({
            div: '.branch-map',
            lat: d.lat,
            lng: d.lng,
            disableDefaultUI: true
        });

        map.addMarker({
            lat: d.lat,
            lng: d.lng
        });
    }


    // gallery component
    $("[data-fancybox]").fancybox({
        loop: true,
        autoFocus: false
    });

});